export enum EBitfStrapiQueryComparator {
  AND = '$and',
  OR = '$or',
  NOT = '$not',
  EQUAL = '$eq',
  NOT_EQUAL = '$ne',
  STARTS_WITH = '$startsWith',
  ENDS_WITH = '$endsWith',
  CONTAINS = '$contains',
  NOT_CONTAINS = '$notContains',
  CONTAINSI = '$containsi',
  NOT_CONTAINSI = '$notContainsi',
  GREATER = '$gt',
  GREATER_EQUAL = '$gte',
  LOWER = '$lt',
  LOWER_EQUAL = '$lte',
  NULL = '$null',
  NOT_NULL = '$notNull',
  IN = '$in',
  NOT_IN = '$notIn',
  BETWEEN = '$between',
}
