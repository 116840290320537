import { BitfStrapiModel } from '@common/libs/bitforce/core/models';
import { User } from './user.model';
import { Group } from './group.model';

export class Agency extends BitfStrapiModel {
  name: string;
  address?: string;
  vat?: string;
  publishedAt?: string;
  locale?: string;
  group: Group[];
  agents: User[];
  accounts: User[];
  secretaries: User[];

  constructor(data: Partial<Agency> = {}) {
    super({ data });

    if (data.agents) {
      this.agents = data.agents.map((agentData) => new User(agentData));
    }
    if (data.accounts) {
      this.accounts = data.accounts.map((accountData) => new User(accountData));
    }
    if (data.secretaries) {
      this.secretaries = data.secretaries.map((secretaryData) => new User(secretaryData));
    }
  }

  get serialised() {
    return {
      id: this.id,
      documentId: this.documentId,
      name: this.name,
      address: this.address,
      vat: this.vat,
      publishedAt: this.publishedAt,
      locale: this.locale,
      group: this.group.map((group) => group.serialised),
      agents: this.agents.map((agent) => agent.serialised),
      accounts: this.accounts.map((account) => account.serialised),
      secretaries: this.secretaries.map((secretary) => secretary.serialised),
    };
  }
}
