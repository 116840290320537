import { IRole } from '@common/interfaces/role.interface';
import { BitfStrapiModel } from '@common/libs/bitforce/core/models';
import { Agency } from './agency.model';
import { Booklet } from './booklet.model';

export class User extends BitfStrapiModel {
  name: string;
  surname: string;
  username: string;
  phoneNumber: number;
  email: string;
  provider: string;
  confirmed: boolean;
  blocked: boolean;
  publishedAt: string;
  locale?: string;
  linkAgentId: string;
  role: IRole;
  agentAgency: Agency;
  agentBooklets: Booklet[];
  photo?: any;
  technicalreportSurveyorBooklets: Booklet[];
  apeSurveyorBooklets: Booklet[];
  extraSurveyorBooklets: Booklet[];
  secretaryBooklets: Booklet[];
  accountBooklets: Booklet[];
  sellerBooklets: Booklet[];
  accountAgencies: Agency[];
  secretaryAgencies: Agency[];
  agentCode: string;

  constructor(data: Partial<User> = {}) {
    super({ data });
    if (data.agentBooklets) {
      this.agentBooklets = data.agentBooklets.map((booklet) => new Booklet(booklet));
    }
    if (data.technicalreportSurveyorBooklets) {
      this.technicalreportSurveyorBooklets = data.technicalreportSurveyorBooklets.map(
        (booklet) => new Booklet(booklet)
      );
    }
    if (data.apeSurveyorBooklets) {
      this.apeSurveyorBooklets = data.apeSurveyorBooklets.map((booklet) => new Booklet(booklet));
    }
    if (data.extraSurveyorBooklets) {
      this.extraSurveyorBooklets = data.extraSurveyorBooklets.map((booklet) => new Booklet(booklet));
    }
    if (data.secretaryBooklets) {
      this.secretaryBooklets = data.secretaryBooklets.map((booklet) => new Booklet(booklet));
    }
    if (data.accountBooklets) {
      this.accountBooklets = data.accountBooklets.map((booklet) => new Booklet(booklet));
    }
    if (data.sellerBooklets) {
      this.sellerBooklets = data.sellerBooklets.map((booklet) => new Booklet(booklet));
    }
    if (data.accountAgencies) {
      this.accountAgencies = data.accountAgencies.map((agency) => new Agency(agency));
    }
    if (data.secretaryAgencies) {
      this.secretaryAgencies = data.secretaryAgencies.map((agency) => new Agency(agency));
    }
  }

  get serialised() {
    return {
      id: this.id,
      name: this.name,
      surname: this.surname,
      documentId: this.documentId,
      phoneNumber: this.phoneNumber,
      username: this.username,
      email: this.email,
      provider: this.provider,
      confirmed: this.confirmed,
      blocked: this.blocked,
      publishedAt: this.publishedAt,
      locale: this.locale,
      linkAgentId: this.linkAgentId,
      role: this.role,
      agentAgency: this.agentAgency.serialised,
      agentBooklets: this.agentBooklets.map((booklet) => booklet.serialised),
      photo: this.photo,
      technicalreportSurveyorBooklets: this.technicalreportSurveyorBooklets.map(
        (booklet) => booklet.serialised
      ),
      apeSurveyorBooklets: this.apeSurveyorBooklets.map((booklet) => booklet.serialised),
      extraSurveyorBooklets: this.extraSurveyorBooklets.map((booklet) => booklet.serialised),
      secretaryBooklets: this.secretaryBooklets.map((booklet) => booklet.serialised),
      accountBooklets: this.accountBooklets.map((booklet) => booklet.serialised),
      sellerBooklets: this.sellerBooklets.map((booklet) => booklet.serialised),
      accountAgencies: this.accountAgencies.map((agency) => agency.serialised),
      secretaryAgencies: this.secretaryAgencies.map((agency) => agency.serialised),
    };
  }

  get canViewLogs() {
    return true;
  }
}
