<mat-card class="p-3 mb-3"
  *ngIf="user">

  <div class="d-flex mt-2">

    <div>
      <div *ngIf="user.photo">
        <img class="avatar-image"
          [src]="user.photo.url"
          alt="Avatar">
      </div>
      <div class="avatar-container"
        *ngIf="!user.photo">
        <mat-icon class="avatar-icon">person</mat-icon>
      </div>
    </div>

    <div class="ml-2">
      <h3 class="m-0">{{ user.name }} {{ user.surname }}</h3>

      <div class="text-muted">{{ title}} </div>

      <div class="d-flex align-items-center justify-content-between mt-2"
        *ngIf="user.phoneNumber">
        <div class="d-flex align-items-center">
          <mat-icon class="info-icon">phone</mat-icon>
          <a class="mat-body-small ml-2">{{ user.phoneNumber ? user.phoneNumber : 'N/D' }}</a>
        </div>

        <a class="mat-body-small text-muted ml-2"
          [href]="'tel:' + user.phoneNumber">
          <u>{{ 'COMMON.LABEL.CALL' | translate }}</u>
        </a>
      </div>

      <div class="d-flex align-items-center justify-content-between mt-2"
        *ngIf="user.email">
        <div class="d-flex align-items-center">
          <mat-icon class="info-icon">email</mat-icon>
          <a class="mat-body-small pl-2 w-100 text-break">{{ user.email ? user.email : 'N/D' }}</a>
        </div>


        <a class="mat-body-small text-muted ml-2"
          [href]="'mailto:' + user.email">
          <u>{{ 'COMMON.LABEL.SEND_EMAIL' | translate }}</u>
        </a>
      </div>

    </div>
  </div>
</mat-card>