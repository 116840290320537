import { HttpHeaders } from '@angular/common/http';

import { BITF_PARSER_HEADER_NAME } from '@bitf/constants';
import { IBitfApiRequest, IBitfRequestMapper } from '@interfaces';
import { EBitfParsers } from '@enums';

import justClone from 'just-clone';

export abstract class BitfRestRequestMapper implements IBitfRequestMapper {
  name = EBitfParsers.BITF_REST_PARSER;

  constructor() {}
  map(apiRequest: IBitfApiRequest) {
    const { body, ...requestParams } = apiRequest;
    // NOTE: We need to clone only request params, not the body here.
    const clonedRequestParams = justClone(requestParams);

    const {
      queryParams,
      file,
      files,
      bodyParser,
      responseType = 'json',
      reportProgress = false,
      skipInterceptors,
    } = clonedRequestParams;

    let { headers } = clonedRequestParams;

    const options: any = {
      responseType,
      headers: new HttpHeaders(),
      withCredentials: false,
      observe: 'response',
    };

    options.params = queryParams || {};

    headers = headers || [];
    // NOTE: add the default header content type
    headers.push({
      headerName: 'Content-Type',
      value: 'application/json',
    });

    headers.push({
      headerName: 'Accept',
      value: 'application/json',
    });

    if (headers && headers.length) {
      headers.forEach((header) => {
        const { headerName, value } = header;
        options.headers = options.headers.append(headerName, value);
      });
    }

    options.reportProgress = reportProgress;

    // NOTE: override Content-Type
    if (bodyParser === 'formUrlEncoded') {
      options.headers = options.headers.set('Content-Type', 'application/form-urlencoded');
    }

    if (file || files) {
      // remove already defined Content-Type header because
      // multipart/form-data; with boundary is automatically added to the request headers
      options.headers = options.headers.delete('Content-Type');
      Object.assign(options, {
        reportProgress: true,
        observe: 'events',
      });
    }

    if (skipInterceptors && skipInterceptors.length) {
      skipInterceptors.forEach((skipInterceptor) => {
        options.headers = options.headers.append(skipInterceptor, '');
      });
    }

    // NOTE: this is needed in the BitfApiEnvelopeMapperInterceptor to
    // map the response with the envelopeMapper specified in the CommonCoreModule
    options.headers = options.headers.append(BITF_PARSER_HEADER_NAME, this.name);

    return options;
  }
}
