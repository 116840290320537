import { Injectable, Injector } from '@angular/core';
import { BitfApiService } from '@bitf/services/api/bitf-api.service';

@Injectable({
  providedIn: 'root',
})
export class RealEstateAgenciesService extends BitfApiService {
  constructor(public injector: Injector) {
    super('real-estate-agencies', injector);
  }
}
