import { Component, Input } from '@angular/core';
import { Booklet } from '@common/core/models';
import { EBookletAttachmentName } from '@common/enums/attachments.enum';
import { Document } from '@common/core/models';

@Component({
  selector: 'casa-doc-booklets-documents',
  templateUrl: './booklets-documents.component.html',
  styleUrl: './booklets-documents.component.scss',
})
export class BookletsDocumentsComponent {
  @Input() booklet: Booklet;
  @Input() isPublicPage: boolean = false;
  @Input() apeSurveyDocument: Document;
  EBookletAttachmentName = EBookletAttachmentName;
}
