import { BitfStrapiModel } from '@common/libs/bitforce/core/models';

export class AgentScoreboard extends BitfStrapiModel {
  myBooklets: number;
  myBookletsWithDeedOfSale: number;
  myPosition: number;
  groupName: string;
  scoreboard: {
    id: number;
    documentId: string;
    name: string | null;
    surname: string | null;
    email?: string;
    booklets: number;
    bookletsWithDeedOfSale: number;
    // Closed booklet count
    agentBooklets: {
      documentId: string;
      id: number;
      propertyDeedOfSale?: Date;
    }[];
  }[];

  constructor(data: Partial<AgentScoreboard> = {}) {
    super({ data });
  }
}
