import { User } from '@common/core/models';

interface IHydrateProperties {
  [property: string]: { model?: any; defaultValue?: any };
}
export abstract class BitfStrapiModel {
  id?: number;
  documentId?: string;
  createdAt?: Date;
  updatedAt?: Date;
  created_by_user?: User;
  updated_by_user?: User;

  private _serializeProperties: string[];

  constructor({
    data,
    hydrateProperties,
    serializeProperties,
  }: {
    data: any;
    hydrateProperties?: IHydrateProperties;
    serializeProperties?: string[];
  }) {
    this._serializeProperties = serializeProperties;

    if (!data.documentId && data.attributes) {
      // NOTE: Strapi response for v4
      Object.assign(this, { id: data?.id, ...data?.attributes });
    } else {
      // NOTE: FE format or Strapi V5 format
      Object.assign(this, data);
    }

    // NOTE: Hydrate default props
    if (this.createdAt) {
      this.createdAt = new Date(this.createdAt);
    }
    if (this.updatedAt) {
      this.updatedAt = new Date(this.updatedAt);
    }

    // NOTE: Hydrate model specific props
    if (hydrateProperties) {
      this.hydrateModel(hydrateProperties);
    }
  }

  protected hydrateModel(hydrateProperties: IHydrateProperties) {
    Object.entries(hydrateProperties).forEach(([property, config]) => {
      const { model, defaultValue } = config;

      // NOTE: If the prop is nullish we don't create an empty object from model (if passed)
      // If is passed the defaultValue we assign that one.
      // In this way we avoid to have all props initialized with empty/nullish objects
      if (
        (this[property]?.hasOwnProperty('data') &&
          (this[property].data === null || this[property].data === undefined)) ||
        this[property] === undefined ||
        this[property] === null
      ) {
        this[property] = defaultValue || undefined;
      } else if (model && this[property] === '') {
        // NOTE: If the prop is an empty string we don't create an empty object from model (if passed)
        this[property] = null;
      } else if (model) {
        if (Array.isArray(this[property]?.data)) {
          // NOTE: As strapi format
          this[property] = this[property].data.map((item) => new model(item));
        } else if (Array.isArray(this[property])) {
          // NOTE: As FE format
          this[property] = this[property].map((item) => new model(item));
        } else if (this[property]?.data) {
          // NOTE: As strapi format
          this[property] = new model(this[property].data);
        } else {
          // NOTE: As strapi flat Object (ex string) or UI format
          this[property] = new model(this[property]);
        }
      }
    });
  }

  get serialised() {
    if (this._serializeProperties) {
      const propertiesMap = this._serializeProperties.map((property) => {
        if (this[property]?.length) {
          if (this[property][0]?.serialised) {
            // NOTE: Array of objects
            return [property, this[property].map((item) => item.serialised?.id || item.serialised)];
          } else {
            // NOTE: Array of non strapi models
            return [property, this[property]];
          }
        } else {
          // NOTE: Not an array, could be an instance of strapi model or any
          return [property, this[property]?.serialised?.id || this[property]];
        }
      });

      return Object.fromEntries(propertiesMap);
    }

    return {};
  }

  protected deEnumize(string: string): string {
    if (string) {
      return string?.replace(/_/g, ' ') || '';
    }
    return string;
  }

  protected enumize(string: string): string {
    if (string) {
      return string?.replace(/ /g, '_') || '';
    }
    return string;
  }
}
