import { BitfStrapiModel } from '@common/libs/bitforce/core/models';
import { StrapiFile } from './strapi-file.model';

export class Document extends BitfStrapiModel {
  name: string;
  type: EDocumentType;
  file: StrapiFile;
  html: string;
  constructor(data: Partial<Document> = {}) {
    super({ data });
  }

  get serialised() {
    return {
      name: this.name,
      type: this.type,
      file: this.file,
      html: this.html,
    };
  }
}

export enum EDocumentType {
  CASA_DOC_CONTRACT = 'CASA_DOC_CONTRACT',
  APE_CONTRACT = 'APE_CONTRACT',
  REQUEST_FOR_DEED_OF_ORIGIN_CONTRACT = 'REQUEST_FOR_DEED_OF_ORIGIN_CONTRACT',
  APE_SURVEY = 'APE_SURVEY',
}
