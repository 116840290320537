import { BitfStrapiModel } from '@common/libs/bitforce/core/models';

export class StrapiFile extends BitfStrapiModel {
  name: string;
  alternativeText: string;
  caption: string;
  width: number;
  height: number;
  formats: any;
  hash: string;
  ext: string;
  mime: string;
  size: number;
  url: string;
  previewUrl: string;
  provider: string;
  provider_metadata: any;
  publishedAt: string;

  constructor(data: Partial<StrapiFile> = {}) {
    super({ data });
  }

  get serialised() {
    return {
      id: this.id,
    };
  }
}
