<div class="d-flex flex-column justify-content-center align-items-center h-full w-full">
  <div class="mt-5">
    <h2>{{'COMMON.LABEL.PAGE_NOT_FOUND' | translate}}</h2>
  </div>
  <div class="mt-3">
    <button mat-flat-button
      [routerLink]="['/']">
      {{'COMMON.LABEL.BACK_TO_HOME' | translate}}
    </button>
  </div>
</div>