import { EApiCallStateNames, EApiRequestPartKeys, EBitfApiSortDirection } from '@enums';
import { IBitfApiCallState } from '@interfaces';
import { DefaultApiCallStateMapper } from './default-api-call-state.mapper';
import {
  BitfStrapiFormItemsConfigApiRequestPart,
  BitfStrapiPaginationApiRequestPart,
  BitfStrapiSearchApiRequestPart,
  BitfStrapiSortApiRequestPart,
} from '@common/libs/bitforce/core/api-call-state/bitf-strapi-api-call-state';

export const apiCallStateConfig = [
  {
    apiCallStateName: EApiCallStateNames.BOOKLETS,
    requestParts: [
      new BitfStrapiPaginationApiRequestPart({
        key: EApiRequestPartKeys.PAGINATION,
        initialPagination: { page: 1, size: 10 },
        options: { pageIndexOffset: 1 },
      }),

      new BitfStrapiSortApiRequestPart({
        key: EApiRequestPartKeys.SORTING,
        defaultPartData: { data: { property: 'createdAt', direction: EBitfApiSortDirection.DESC } },
      }),
      new BitfStrapiSearchApiRequestPart(),
      new BitfStrapiFormItemsConfigApiRequestPart(),
    ],
    apiCallStateMapper: new DefaultApiCallStateMapper(),
  } as IBitfApiCallState,
];
