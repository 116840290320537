import { EBookletAttachmentName } from '@common/enums/attachments.enum';
import { StrapiFile } from '@web/core/models';

type BookletAttachmentsMap = {
  [key in EBookletAttachmentName]?: BookletAttachmentComponent;
};

export class BookletAttachments implements BookletAttachmentsMap {
  id?: number;
  [EBookletAttachmentName.APE]?: BookletAttachmentComponent;
  [EBookletAttachmentName.LAND_REGISTRY]?: BookletAttachmentComponent;
  [EBookletAttachmentName.DEED_OF_ORIGIN]?: BookletAttachmentComponent;
  [EBookletAttachmentName.URBAN_PLANNING]?: BookletAttachmentComponent;
  [EBookletAttachmentName.OCCUPANCY_PERMIT]?: BookletAttachmentComponent;
  [EBookletAttachmentName.SYSTEMS]?: BookletAttachmentComponent;
  [EBookletAttachmentName.BOILER]?: BookletAttachmentComponent;
  [EBookletAttachmentName.CDU]?: BookletAttachmentComponent;
  [EBookletAttachmentName.LATENT_DEFECTS_INSURANCE]?: BookletAttachmentComponent;
  [EBookletAttachmentName.TECHNICAL_REPORT]?: BookletAttachmentComponent;
  [EBookletAttachmentName.EXTRA]?: BookletAttachmentComponent;
  [EBookletAttachmentName.EXTRA_CONTRACT]?: BookletAttachmentComponent;
  [EBookletAttachmentName.SELLER_IDENTITY_CARD]?: BookletAttachmentComponent;
  [EBookletAttachmentName.CASA_DOC_CONTRACT]?: BookletAttachmentComponent;
  [EBookletAttachmentName.APE_SURVEYOR_SURVEY]?: BookletAttachmentComponent;
  [EBookletAttachmentName.APE_SURVEYOR_PICTURES]?: BookletAttachmentComponent;

  constructor(data: Partial<BookletAttachments> = {}) {
    Object.assign(this, data);

    for (const attachmentName in EBookletAttachmentName) {
      if (EBookletAttachmentName.hasOwnProperty(attachmentName)) {
        const name = EBookletAttachmentName[attachmentName];
        if (data?.[name]) {
          this[name] = new BookletAttachmentComponent(data[name]);
        }
      }
    }
  }

  get serialised() {
    // cycle over all the attachment names and return the serialised version of each
    const serialisedAttachments = {};
    for (const attachmentName in EBookletAttachmentName) {
      if (EBookletAttachmentName.hasOwnProperty(attachmentName)) {
        const name = EBookletAttachmentName[attachmentName];
        serialisedAttachments[name] = this[name]?.serialised;
      }
    }
    return {
      id: this.id,
      ...serialisedAttachments,
    };
  }
}

export class BookletAttachmentComponent {
  id?: number;
  note?: string;
  file?: StrapiFile[];
  compliant?: boolean;
  visible?: boolean;
  paid?: boolean;
  paymentLink?: string;
  signed?: boolean;

  constructor(data: Partial<BookletAttachmentComponent> = {}) {
    Object.assign(this, data);

    if (data?.file) {
      this.file = data.file.map((file) => new StrapiFile(file));
    }
  }

  get serialised() {
    return {
      id: this.id,
      note: this.note,
      file: this.file?.map((file) => file.serialised),
      visible: this.visible,
      compliant: this.compliant,
      paid: this.paid,
      paymentLink: this.paymentLink,
      signed: this.signed,
    };
  }
}
