<div class="header">
  <mat-toolbar color="primary"
    class="border-bottom border-secondary">
    <div class="col p-0 d-flex justify-content-start h-100">
      <img src="/assets/images/logo_casadoc.svg"
        class="logo-casadoc cursor-pointer"
        [routerLink]="'/'">
    </div>
    <div class="col">
    </div>

    <div class="col px-0 d-flex justify-content-end"
      *ngIf="storeService.store?.user">
      <button mat-icon-button
        aria-label="Menu"
        (click)="onOpenSidenav()">
        <mat-icon>menu</mat-icon>
      </button>
    </div>
  </mat-toolbar>
</div>