export enum EApiCallStateNames {
  BOOKLETS = 'BOOKLETS',
}

export enum EApiRequestPartKeys {
  SEARCH = 'SEARCH',
  PAGINATION = 'PAGINATION',
  FILTERS = 'FILTERS',
  SORTING = 'SORTING',
}
