<ng-template #wrapper>
  <mat-expansion-panel *ngIf="document()?.visible !== false && isVisible() !== false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="document-title">
          {{ documentName() }}
          <ng-content>({{document()?.file?.length || 0 }})</ng-content>
          <span *ngIf="isRequired()"
            class="error ml-1">*</span>
        </div>
      </mat-panel-title>
      <mat-panel-description class="justify-content-end">
        <mat-icon class="mr-2"
          *ngIf="document()?.note">info</mat-icon>
        <ng-container *ngIf="document()?.file?.length">
          <mat-icon class="mr-2"
            [ngClass]="{'success': document()?.paid, 'error': !document()?.paid}"
            *ngIf="checkPayment() === true && document()">euro</mat-icon>
          <mat-icon class="mr-2"
            [ngClass]="{'success': document()?.signed, 'error': !document()?.signed}"
            *ngIf="checkSignature() === true && document()"
            inline>signature</mat-icon>
          <mat-icon class="success mr-2"
            *ngIf="checkCompliance() === true && document()?.compliant">check_circle</mat-icon>
          <mat-icon class="warning mr-2"
            *ngIf="checkCompliance() && document()?.compliant === false">warning</mat-icon>
        </ng-container>
      </mat-panel-description>

    </mat-expansion-panel-header>

    <div class="d-flex flex-column align-items-center mb-2 warning-notification p-2 pr-3"
      *ngIf="showWarningMessage()">
      <ul class="pl-3">
        <li *ngIf="checkCompliance() === true && document()?.compliant === false">
          {{ 'BOOKLET_ATTACHMENT.WARNING_MESSAGE.CHECK_COMPLIANT' | translate}}
        </li>
        <li *ngIf="checkPayment() === true && document()?.paid === false">
          {{ 'BOOKLET_ATTACHMENT.WARNING_MESSAGE.PAYMENT_REQUIRED' | translate}}
        </li>
        <li *ngIf="checkSignature() === true && document()?.signed === false">
          {{ 'BOOKLET_ATTACHMENT.WARNING_MESSAGE.CHECK_SIGNATURE' | translate}}
        </li>
      </ul>
    </div>

    <mat-tab-group mat-align-tabs="start"
      mat-stretch-tabs="false"
      animationDuration="0ms"
      [selectedIndex]="selectedTab.value"
      (selectedIndexChange)="selectedTab.setValue($event)">
      <mat-tab [label]="'BOOKLET_ATTACHMENT.TABS.FILES' | translate">
        <h5 class="d-flex justify-content-center mt-3"
          *ngIf="!document()?.file?.length">{{ 'BOOKLET_ATTACHMENT.NO_FILES_UPLOADED' | translate}}</h5>
        <mat-list *ngIf="document()?.file?.length"
          class="mt-3">
          <mat-list-item *ngFor="let file of document().file; let index = index;"
            class="p-0">
            <div class="row m-0 w-100">
              <div class="col-2 pl-0 pr-0">
                <container-element [ngSwitch]="true">
                  <mat-icon *ngSwitchCase="file.ext ==='.pdf'">picture_as_pdf</mat-icon>
                  <mat-icon *ngSwitchCase="['.jpg', '.png', '.jpeg'].includes(file.ext)"
                    (click)="showImagePreview(file)">image</mat-icon>
                  <mat-icon *ngSwitchDefault>image</mat-icon>
                </container-element>
              </div>
              <div class="col-17 mat-body-small text-truncate">
                {{ file.name }} <br> ({{file.size | bitfBytes}})
              </div>
              <div class="col-5 pl-0 pr-0">
                <div class="w-100 h-100 d-flex align-items-start justify-content-end">
                  <mat-icon *ngIf="document().paid || checkPayment() === false"
                    (click)="onDownloadDocument(file)"
                    class="ml-2">download</mat-icon>
                  <mat-icon *ngIf="checkPayment() ===  true && !document().paid"
                    class="ml-2">lock</mat-icon>
                  <mat-icon *ngIf="canDelete()"
                    (click)="onDeleteFile(file)"
                    class="ml-2">delete</mat-icon>
                </div>
              </div>
            </div>
          </mat-list-item>
        </mat-list>
      </mat-tab>
      <mat-tab [label]="'BOOKLET_ATTACHMENT.TABS.UPLOAD' | translate"
        *ngIf="canUpload()">
        <casa-doc-file-uploader [fileUploaderConfig]="fileUploaderConfig()"
          (startUpload)="onStartUpload()"></casa-doc-file-uploader>
      </mat-tab>
      <mat-tab [label]="'BOOKLET_ATTACHMENT.TABS.NOTE' | translate"
        *ngIf="document()?.note">
        <div class="mt-3">
          {{ document()?.note }}
        </div>
      </mat-tab>
      <mat-tab [label]="'BOOKLET_ATTACHMENT.TABS.PRICE' | translate"
        *ngIf="price()">
        <div class="mt-3 mat-body-small"
          [innerHTML]="price()">
        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-expansion-panel>
</ng-template>