<div class="page-header row d-flex align-items-center">
  <div class="col d-flex flex-grow-1 align-items-center">
    <button mat-icon-button
      *ngIf="backUrl"
      class="mr-2"
      [routerLink]="backUrl"><mat-icon>arrow_back_ios_new</mat-icon></button>
    <h1 class="m-0"
      [routerLink]="backUrl">{{pageTitle | translate}}</h1>
  </div>

  <div class="col-auto d-flex justify-content-end pl-0">
    <ng-content select="[button-right]"></ng-content>
  </div>
</div>