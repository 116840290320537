export enum EBookletAttachmentName {
  APE = 'ape',
  LAND_REGISTRY = 'landRegistry',
  DEED_OF_ORIGIN = 'deedOfOrigin',
  URBAN_PLANNING = 'urbanPlanning',
  OCCUPANCY_PERMIT = 'occupancyPermit',
  SYSTEMS = 'systems',
  BOILER = 'boiler',
  CDU = 'cdu',
  LATENT_DEFECTS_INSURANCE = 'latentDefectsInsurance',
  TECHNICAL_REPORT = 'technicalReport',
  EXTRA = 'extra',
  EXTRA_CONTRACT = 'extraContract',
  SELLER_IDENTITY_CARD = 'sellerIdentityCard',
  CASA_DOC_CONTRACT = 'casaDocContract',
  APE_SURVEYOR_SURVEY = 'apeSurveyorSurvey',
  APE_SURVEYOR_PICTURES = 'apeSurveyorPictures',
}
