import { Injectable, Injector } from '@angular/core';
import { BitfStrapiRestAuthService } from '@common/libs/bitforce/core/services/auth/strapi-auth/bitf-strapi-rest-auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BitfStrapiRestAuthService {
  tokenAlertTresholdMinutes = 5;
  constructor(protected injector: Injector) {
    super(injector);
  }
}
