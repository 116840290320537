import { EBitfApiSortDirection, EBitfParsers } from '@enums';
import { IBitfApiRequest, IBitfApiSorting } from '../../services/api/bitf-api.interface';
import { IBitfRequestMapper } from '../bitf-parsers.interface';
import { BitfRestRequestMapper } from '../rest-parser';
import { IBitfStrapiFormItemsConfigFilter } from './bitf-strapi-api.interface';
import { EBitfStrapiQueryComparator, EBitfStrapiQueryOperator } from './';
import qs from 'qs';
import merge from 'deepmerge';
import deepmerge from 'deepmerge';
export class BitfStrapiRequestMapper extends BitfRestRequestMapper implements IBitfRequestMapper {
  name = EBitfParsers.BITF_STRAPI_PARSER;

  constructor() {
    super();
  }

  map(requestParams: IBitfApiRequest) {
    const {
      search,
      filter,
      sorting,
      page,
      size,
      populate,
      fields,
      query,
      // queryParams,
      apiParser,
    } = requestParams;
    requestParams.apiParser = apiParser || EBitfParsers.BITF_STRAPI_PARSER;
    const requestMapped = super.map(requestParams);

    const strapiQueryParams: any = { filters: { $or: [] }, pagination: {} };

    if (search) {
      Object.entries(search).forEach(([key, value]) => {
        strapiQueryParams.filters.$or.push({ [key]: { [EBitfStrapiQueryComparator.CONTAINS]: value } });
      });
    }

    // Query is used only for FormItemsConfig
    if (query?.length) {
      (query as IBitfStrapiFormItemsConfigFilter[])
        .filter(queryItem => queryItem.property)
        .forEach(queryItem => {
          // Ref: { [queryItem.property]: { [queryItem.comparator]: queryItem.value } };
          const condition = this.buildCondition(
            queryItem.property,
            {
              [queryItem.comparator]: queryItem.value,
            },
            queryItem.metaData
          );
          if (queryItem.operator === EBitfStrapiQueryOperator.AND) {
            Object.assign(strapiQueryParams.filters, deepmerge(strapiQueryParams.filters, condition));
          } else {
            strapiQueryParams.filters.$or.push(condition);
          }
        });
    }

    if (page !== undefined) {
      strapiQueryParams.pagination.page = page;
    }

    if (size !== undefined) {
      strapiQueryParams.pagination.pageSize = size;
    }

    if (sorting?.length) {
      if (!sorting.some(sort => sort.property === 'id')) {
        sorting.push({ property: 'id', direction: EBitfApiSortDirection.DESC });
      }
      strapiQueryParams.sort = sorting.map(
        (sort: IBitfApiSorting) => `${sort.property}:${sort.direction.toLowerCase()}`
      );
    }

    if (populate) {
      strapiQueryParams.populate = populate;
    }

    if (fields && fields.length) {
      strapiQueryParams.fields = fields;
    }

    if (filter) {
      strapiQueryParams.filters = merge(strapiQueryParams.filters, filter);
    }

    Object.assign(requestMapped.params, this.mapStrapiQueryParams(strapiQueryParams));

    return requestMapped;
  }

  private mapStrapiQueryParams(strapiQueryParams) {
    const stringifiedStrapiQueryParams = qs.stringify(strapiQueryParams, { encode: false });

    if (!stringifiedStrapiQueryParams) {
      return {};
    }

    const mappedStrapiQueryParams = stringifiedStrapiQueryParams.split('&').map(rule => rule.split('='));

    return Object.fromEntries(mappedStrapiQueryParams);
  }

  private buildCondition(key: string, objValue: any, metaData?: any): any {
    const filterObject = {};
    key.split('.').reduce((o, s, index) => {
      if (index >= key.split('.').length - 1) {
        o[s] = objValue;
      } else {
        o[s] = {};
      }
      return o[s];
    }, filterObject);

    // Add custom filters from the BE
    /**
     * Example BE Form Item
     * metaData: {
        filter: {
          property: {
            value: "Demo",
          },
        },
      },
     */
    if (metaData?.filter) {
      Object.assign(filterObject, deepmerge(filterObject, metaData?.filter));
    }
    return filterObject;
  }
}
