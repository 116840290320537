import { Component, Injector, OnInit } from '@angular/core';
import { BitfFileUploaderComponent } from '@common/libs/bitforce/core/components/ui/file-uploader/bitf-file-uploader.component';

@Component({
  selector: 'casa-doc-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
})
export class FileUploaderComponent extends BitfFileUploaderComponent<any> implements OnInit {
  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }
}
