import * as Models from '@models';

export const modelsMap = new Map<string, any>([
  ['users', Models.User],
  ['real-estate-agencies', Models.Agency],
  ['real-estate-agents', Models.User],
  ['groups', Models.Group],
  ['agencies', Models.Agency],
  ['booklets', Models.Booklet],
  ['documents-archive', Models.Document],
  ['upload', Models.StrapiFile],
  ['scoreboard-agent', Models.AgentScoreboard],
]);
