import { Component, Input } from '@angular/core';

@Component({
  selector: 'casa-doc-page-header',
  templateUrl: './page-header.component.html',
  styleUrl: './page-header.component.scss',
})
export class PageHeaderComponent {
  @Input() pageTitle: string;
  @Input() backUrl: string;
}
