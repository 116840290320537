import { BitfStrapiModel } from '@common/libs/bitforce/core/models';
import { Agency } from './agency.model';

export class Group extends BitfStrapiModel {
  name: string;
  companyLogo?: string;
  publishedAt?: string;
  companyLogoPosition?: string;
  casaDocLogoPosition?: string;
  agencies: Agency[];

  constructor(data: Partial<Group> = {}) {
    super({ data });

    if (data.agencies) {
      this.agencies = data.agencies.map((agency) => new Agency(agency));
    }
  }

  get serialised() {
    return {
      name: this.name,
      companyLogo: this.companyLogo,
      publishedAt: this.publishedAt,
      companyLogoPosition: this.companyLogoPosition,
      casaDocLogoPosition: this.casaDocLogoPosition,
      agencies: this.agencies.map((agency) => agency.serialised),
    };
  }
}
