<div class="sidenav-sm p-0 w-100 h-100">
  <div class="d-flex w-100 h-100 flex-column p-4">

    <div class="d-flex">
      <span class="flex-grow-1"></span>
      <button mat-icon-button
        (click)="closeSidenav()">
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <div class="align-items-start pt-3"
      *ngIf="storeService.store?.user">
      <span class="title">
        {{'COMMON.LABEL.HI' | translate}}<span
          *ngIf="storeService.store.user?.role.name !== eUsersRole.AGENT">!</span>
        <span *ngIf="storeService.store.user?.role.name === eUsersRole.AGENT">,
          {{storeService.store?.user?.name}}
          {{storeService.store?.user?.surname}}</span>
      </span>
      <div>
        <span class="subtitle">{{storeService?.store?.user?.email}}</span>
      </div>

      <div class="mt-4 d-flex justify-content-between role px-2 py-1 w-100 align-items-center rounded-pill"
        *ngIf="storeService.store.user?.role.name === eUsersRole.AGENT">
        <div class="d-flex align-items-center">
          <span>
            {{'COMMON.LABEL.AGENT_ID' | translate}}
          </span>

          <span class="agentCode">
            : {{storeService.store.user?.agentCode}}
          </span>
        </div>

        <button mat-icon-button
          [cdkCopyToClipboard]="storeService.store?.user?.agentCode"><mat-icon>content_copy</mat-icon>
        </button>
      </div>

    </div>

    <mat-divider class="mt-4 mb-4"></mat-divider>

    <div class="flex-grow-1">
      <mat-nav-list>
        <a mat-list-item
          #linkActive="routerLinkActive"
          routerLinkActive
          [activated]="linkActive.isActive"
          routerLink="/"
          class="mb-2"
          (click)="closeSidenav()">
          <mat-icon matListItemIcon>menu_book</mat-icon>
          <div matListItemTitle>{{'COMMON.LABEL.BOOKLETS' | translate}}</div>
        </a>
      </mat-nav-list>
      <div class="text-center">
        <span>
          {{'COMMON.LABEL.NEED_HELP_MESSAGE' | translate}}
        </span>
        <br>
        <span>
          <a href="mailto:{{infoMail}}">
            {{infoMail}}
          </a>
        </span>
      </div>
    </div>


    <div class="d-flex">
      <span class="d-flex mat-body-small align-items-center">
        v{{appVersion}}
      </span>
      <div class="ml-1">
        <bitf-mat-language-selector></bitf-mat-language-selector>
      </div>
      <span class="flex-grow-1"></span>
      <button mat-flat-button
        class="error"
        (click)="onSignOut()">
        Logout
      </button>
    </div>

  </div>
</div>