import { BitfApiRequestPart } from '@bitf/core/api-call-state/bitf-api-request-part';
import { bitfGetProp } from '@common/libs/bitforce/utils/bitf-objects.utils';
import { EBitfApiRequestPartsNames, EApiRequestPartKeys } from '@enums';
import { IBitfPartData } from '@interfaces';
import { EBitfStrapiQueryComparator, IBitfStrapiFormItemsConfigFilter } from '../../parsers/strapi-parser';

export class BitfStrapiFormItemsConfigApiRequestPart extends BitfApiRequestPart {
  constructor({
    key,
    partName,
    defaultPartData,
  }: {
    key?: EApiRequestPartKeys;
    partName?: EBitfApiRequestPartsNames;
    defaultPartData?: IBitfPartData;
  } = {}) {
    if (!key) {
      key = EApiRequestPartKeys.FILTERS;
    }
    if (!partName) {
      partName = EBitfApiRequestPartsNames.QUERY;
    }
    super(key, partName, defaultPartData);
  }

  build(): void {
    const queryItems = [];
    if (this.formValue) {
      // NOTE: Filter empty values
      Object.entries(this.formValue)
        .filter(([_, value]) => {
          if (Array.isArray(value)) {
            return value.length;
          }
          return value !== null && value !== '' && value !== undefined;
        })
        .forEach(([key, value]: [string, any]) => {
          let mappedValue = value;
          // NOTE: check if the value contains an object, if it's the case
          // we extract the id
          if (Array.isArray(value) && value.length && value[0].id) {
            mappedValue = value.map(_value => _value.id);
          } else if (value.id) {
            mappedValue = value.id;
          }

          queryItems.push({
            property: this.mapName(key),
            value: mappedValue,
            comparator: this.data?.formItemsConfig?.[key]?.comparator || EBitfStrapiQueryComparator.EQUAL,
            operator: this.data?.formItemsConfig?.[key]?.operator || EBitfStrapiQueryComparator.AND,
            metaData: this.data?.formItemsConfig?.[key]?.metaData,
          } as IBitfStrapiFormItemsConfigFilter);
        });
    }
    this.part = queryItems;
  }

  private mapName(key: string) {
    const mapName = bitfGetProp(this.data, 'formItemsConfig', key, 'mapName');
    if (mapName || mapName === null) {
      return mapName;
    } else {
      return key;
    }
  }
}
