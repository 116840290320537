import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';

import { IBitfApiRequest, IBitfApiResponse } from '@interfaces';
import { BitfStrapiAuth } from '@bitf/core/models';
import { BitfApiService } from '../../api/bitf-api.service';

@Injectable({
  providedIn: 'root',
})
export class BitfStrapiJwtService extends BitfApiService {
  constructor(public injector: Injector) {
    super('auth', injector);
  }

  getToken(requestParams: IBitfApiRequest = {}): Observable<IBitfApiResponse<BitfStrapiAuth>> {
    return super.post<BitfStrapiAuth>({ ...requestParams });
  }
}
