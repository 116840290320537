import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: '',
    loadChildren: () => import('./modules/layout/layout.module').then((m) => m.LayoutModule),
  },

  // { path: '', redirectTo: 'm', pathMatch: 'full' },

  // NOTE: do not change the order or routes
  // {
  //   path: '',
  //   loadChildren: () => import('./modules/desktop/desktop.module').then(m => m.DesktopModule),
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
