<div class="mb-2 mat-title-medium">{{ 'BOOKLET_ATTACHMENT.TECHNICAL_REPORT_DOCUMENTS' | translate }}</div>
<mat-accordion multi>
  <casa-doc-document-manager [attachmentName]="EBookletAttachmentName.TECHNICAL_REPORT"
    [booklet]="booklet"
    [documentName]="'BOOKLET_ATTACHMENT.NAME.TECHNICAL_REPORT' | translate"
    [uploadPath]="booklet?.documentId"
    [checkCompliance]="true"
    [checkPayment]="true"
    [price]="booklet?.technicalReportPrice && booklet?.accessToRecordsPrice && 'BOOKLET_ATTACHMENT.TECHNICAL_REPORT_PAYMENT_DESCRIPTION' | translate: { technicalReportPrice: booklet?.technicalReportPrice, accessToRecordsPrice: booklet?.accessToRecordsPrice}"
    [canUpload]="isPublicPage ? false : false"
    [canDelete]="isPublicPage ? false : false"></casa-doc-document-manager>
  <casa-doc-document-manager [attachmentName]="EBookletAttachmentName.DEED_OF_ORIGIN"
    [booklet]="booklet"
    [documentName]="'BOOKLET_ATTACHMENT.NAME.DEED_OF_ORIGIN' | translate"
    [fileUploaderConfig]="{ extensions: ['pdf', 'png', 'jpg']}"
    [uploadPath]="booklet?.documentId"
    [checkCompliance]="true"
    [checkPayment]="booklet?.requestForDeedOfOrigin"
    [price]="booklet?.requestForDeedOfOrigin && booklet?.requestForDeedOfOriginPrice && 'BOOKLET_ATTACHMENT.PAYMENT_ACTIVITY_DESCRIPTION' | translate: { price: booklet?.requestForDeedOfOriginPrice}"
    [canUpload]="isPublicPage ? false : !booklet?.requestForDeedOfOrigin"
    [canDelete]="isPublicPage ? false : !booklet?.requestForDeedOfOrigin"></casa-doc-document-manager>
  <casa-doc-document-manager [attachmentName]="EBookletAttachmentName.LAND_REGISTRY"
    [booklet]="booklet"
    [documentName]="'BOOKLET_ATTACHMENT.NAME.LAND_REGISTRY' | translate"
    [uploadPath]="booklet?.documentId"
    [checkCompliance]="true"
    [checkPayment]="false"
    [canUpload]="isPublicPage ? false : true"
    [canDelete]="isPublicPage ? false : true"></casa-doc-document-manager>
  <casa-doc-document-manager [attachmentName]="EBookletAttachmentName.URBAN_PLANNING"
    [booklet]="booklet"
    [documentName]="'BOOKLET_ATTACHMENT.NAME.URBAN_PLANNING' | translate"
    [uploadPath]="booklet?.documentId"
    [checkCompliance]="true"
    [checkPayment]="false"
    [canUpload]="isPublicPage ? false : true"
    [canDelete]="isPublicPage ? false : true"></casa-doc-document-manager>
  <casa-doc-document-manager [attachmentName]="EBookletAttachmentName.OCCUPANCY_PERMIT"
    [booklet]="booklet"
    [documentName]="'BOOKLET_ATTACHMENT.NAME.OCCUPANCY_PERMIT' | translate"
    [uploadPath]="booklet?.documentId"
    [checkCompliance]="true"
    [checkPayment]="false"
    [canUpload]="isPublicPage ? false : true"
    [canDelete]="isPublicPage ? false : true"></casa-doc-document-manager>
  <casa-doc-document-manager [attachmentName]="EBookletAttachmentName.SYSTEMS"
    [booklet]="booklet"
    [documentName]="'BOOKLET_ATTACHMENT.NAME.SYSTEMS' | translate"
    [uploadPath]="booklet?.documentId"
    [checkCompliance]="true"
    [checkPayment]="false"
    [canUpload]="isPublicPage ? false : true"
    [canDelete]="isPublicPage ? false : true"></casa-doc-document-manager>
  <casa-doc-document-manager [attachmentName]="EBookletAttachmentName.BOILER"
    [booklet]="booklet"
    [documentName]="'BOOKLET_ATTACHMENT.NAME.BOILER' | translate"
    [uploadPath]="booklet?.documentId"
    [checkCompliance]="true"
    [checkPayment]="false"
    [canUpload]="isPublicPage ? false : true"
    [canDelete]="isPublicPage ? false : true"></casa-doc-document-manager>
  <casa-doc-document-manager [attachmentName]="EBookletAttachmentName.CDU"
    [booklet]="booklet"
    [documentName]="'BOOKLET_ATTACHMENT.NAME.CDU' | translate"
    [uploadPath]="booklet?.documentId"
    [checkCompliance]="true"
    [checkPayment]="false"
    [canUpload]="isPublicPage ? false : true"
    [canDelete]="isPublicPage ? false : true"></casa-doc-document-manager>
  <casa-doc-document-manager [attachmentName]="EBookletAttachmentName.LATENT_DEFECTS_INSURANCE"
    [booklet]="booklet"
    [documentName]="'BOOKLET_ATTACHMENT.NAME.LATENT_DEFECTS_INSURANCE' | translate"
    [uploadPath]="booklet?.documentId"
    [checkCompliance]="true"
    [checkPayment]="false"
    [canUpload]="isPublicPage ? false : true"
    [canDelete]="isPublicPage ? false : true"></casa-doc-document-manager>
</mat-accordion>

<ng-container *ngIf="booklet?.bookletAttachments?.[EBookletAttachmentName.APE]?.visible !== false">
  <div class="mt-3 mb-2 d-flex align-items-center justify-content-between">
    <div class="mat-title-medium">{{ 'BOOKLET_ATTACHMENT.APE_DOCUMENTS' | translate }}</div>
    <a mat-button
      *ngIf="apeSurveyDocument && booklet?.requestForApe"
      [href]="apeSurveyDocument?.file?.url"
      target="_blank">{{ 'BOOKLET_ATTACHMENT.APE_SURVEY' | translate }}</a>
  </div>

  <mat-accordion multi>
    <casa-doc-document-manager [attachmentName]="EBookletAttachmentName.APE"
      [booklet]="booklet"
      [documentName]="'BOOKLET_ATTACHMENT.NAME.APE' | translate"
      [fileUploaderConfig]="{ extensions: ['pdf', 'png', 'jpg']}"
      [uploadPath]="booklet?.documentId"
      [checkCompliance]="true"
      [checkPayment]="booklet?.requestForApe"
      [price]="booklet?.requestForApe && booklet?.apePrice && 'BOOKLET_ATTACHMENT.PAYMENT_ACTIVITY_DESCRIPTION' | translate: { price: booklet?.apePrice}"
      [canUpload]="isPublicPage ? false : !booklet?.requestForApe"
      [canDelete]="isPublicPage ? false : !booklet?.requestForApe"></casa-doc-document-manager>
    <casa-doc-document-manager [attachmentName]="EBookletAttachmentName.APE_SURVEYOR_PICTURES"
      [booklet]="booklet"
      [documentName]="'BOOKLET_ATTACHMENT.NAME.APE_SURVEYOR_PICTURES' | translate"
      [fileUploaderConfig]="{ extensions: ['pdf', 'png', 'jpg']}"
      [uploadPath]="booklet?.documentId"
      [checkCompliance]="true"
      [checkPayment]="false"
      [canUpload]="isPublicPage ? false : true"
      [canDelete]="isPublicPage ? false : true"
      [isVisible]="booklet?.requestForApe"></casa-doc-document-manager>
    <casa-doc-document-manager [attachmentName]="EBookletAttachmentName.APE_SURVEYOR_SURVEY"
      [booklet]="booklet"
      [documentName]="'BOOKLET_ATTACHMENT.NAME.APE_SURVEYOR_SURVEY' | translate"
      [fileUploaderConfig]="{ extensions: ['pdf', 'png', 'jpg']}"
      [uploadPath]="booklet?.documentId"
      [checkCompliance]="true"
      [checkPayment]="false"
      [canUpload]="isPublicPage ? false : true"
      [canDelete]="isPublicPage ? false : true"
      [isVisible]="booklet?.requestForApe"></casa-doc-document-manager>
  </mat-accordion>
</ng-container>

<ng-container *ngIf="booklet?.bookletAttachments?.[EBookletAttachmentName.EXTRA_CONTRACT]?.signed">
  <div class="mt-3 mb-2 mat-title-medium">{{ 'BOOKLET_ATTACHMENT.EXTRA_DOCUMENTS' | translate }}</div>

  <mat-accordion multi>
    <casa-doc-document-manager [attachmentName]="EBookletAttachmentName.EXTRA"
      [booklet]="booklet"
      [documentName]="'BOOKLET_ATTACHMENT.NAME.EXTRA' | translate"
      [uploadPath]="booklet?.documentId"
      [checkCompliance]="true"
      [checkPayment]="booklet?.bookletAttachments?.[EBookletAttachmentName.EXTRA_CONTRACT]?.signed"
      [price]="booklet?.extraPrice && 'BOOKLET_ATTACHMENT.PAYMENT_ACTIVITY_DESCRIPTION' | translate: { price: booklet?.extraPrice}"
      [canUpload]="isPublicPage ? false : false"
      [canDelete]="isPublicPage ? false : false"></casa-doc-document-manager>
  </mat-accordion>
</ng-container>