import { Injectable, Injector } from '@angular/core';
import { BitfApiService } from '@common/libs/bitforce/core/services/api/bitf-api.service';

@Injectable({
  providedIn: 'root',
})
export class AgenciesService extends BitfApiService {
  constructor(public injector: Injector) {
    super('agencies', injector);
  }
}
